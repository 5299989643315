@layer base {
  * {
    @apply border-border break-words;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* scrollbar start */
/* Scrollbar styling for WebKit-based browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
}
*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* Scrollbar styling for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* scrollbar end */

/* hide tooltip is trigger element is not in viewport */
[data-popper-reference-hidden="true"] {
  display: none;
}

/* Range knob start */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--foreground);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background-color: var(--foreground);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
/* Range knob end */

/* Overrides base default */
input[type="checkbox"] {
  cursor: pointer;
}
/* Checkbox as radio start */
.radio-checkmark {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.radio-checkmark:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

/* Checkbox as radio end */

@media print {
  @page {
    /*size: A4 portrait;*/
    size: 330mm 427mm;
    margin: 0mm;
  }
  svg {
    max-width: 95vw;
  }
  body {
    -webkit-print-color-adjust: exact;
    -ms-zoom: 1.665;
    margin: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-header-group;
  }
  .break-inside-avoid {
    break-inside: avoid;
  }
  .break-after-avoid {
    break-after: avoid;
  }
  .break-before-always {
    break-before: always;
  }
}
