/* 
  Note: 
  "-r" describes its responsive design
  made with desktop first approach i.e. name describes its size in the range as follows
  mobile screens <<---->| md ---- to ---- lg |<---->> wide screens 
*/

.h1-r {
  @apply text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl xxl:text-8xl;
}
.h2-r {
  @apply text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl xxl:text-7xl;
}
.h3-r {
  @apply text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl xxl:text-6xl;
}
.h4-r {
  @apply text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl xxl:text-5xl;
}
.h5-r {
  @apply text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl xxl:text-4xl;
}

.text-xxs-r {
  @apply text-xxxs md:text-xxs xl:text-xs xxl:text-sm;
}
.text-xs-r {
  @apply text-xxs md:text-xs xl:text-sm xxl:text-base;
}
.text-sm-r {
  @apply text-xs md:text-sm xl:text-base xxl:text-lg;
}
.text-base-r {
  @apply text-sm md:text-base xl:text-lg xxl:text-xl;
}
.text-lg-r {
  @apply text-base md:text-lg xl:text-xl xxl:text-2xl;
}
.text-xl-r {
  @apply text-lg md:text-xl xl:text-2xl xxl:text-3xl;
}
