input[type="color"] {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 50%;
  padding: 0;
}
