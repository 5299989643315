/* TODO #1 - convert to tailwind */
/* TODO #2 - pull out toolbar height, padding/margins etc required for calculating positions into variables. */

/* editor container override */

.editor-container-fp {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.editor-inner-fp {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 95vw;
}
@media (min-width: 768px) {
  .editor-inner-fp {
    width: 85vw;
  }
}

.editor-input-fp {
  background: #fff;
  resize: none;
  height: 100%;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  caret-color: #444;
  flex-grow: 1;
  overflow: hidden;
}

.editor-placeholder-fp {
  color: #999;
  overflow: hidden;
  top: 33px;
  position: absolute;
  text-overflow: ellipsis;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}
