[type="radio"] {
  /* color: #0969da;
  width: 23px;
  height: 23px; */
  @apply bg-transparent w-5 h-5 cursor-pointer focus:outline-none focus:ring-0 focus:ring-inset !important;
}

[type="radio"]:checked {
  /* box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12); */
  @apply bg-secondary animate-mildFade !important;

}
