/*   GRAPH TABLE */
.highcharts-data-table table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  margin-top: 10px;
  font-size: 0.9em;
  @apply bg-background text-foreground;
}

.highcharts-data-table th {
  @apply font-medium text-black bg-gray-100 dark:text-gray-100 dark:bg-black;
}

.highcharts-data-table td {
  @apply text-gray-800 dark:text-gray-300;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  /*border: 1px solid silver;*/
  padding: 0.5em;
  @apply border border-gray-400 dark:border-gray-700;
}

.highcharts-data-table tr:nth-child(even),
.highcharts-data-table thead tr {
  /* for alternate rows */
  /*@apply bg-gray-100 dark:bg-gray-900 */
}
.highcharts-data-table tr:hover {
  /*background: #eff; */
  @apply bg-gray-100 dark:bg-black;
}
.highcharts-data-table caption {
  display: none;
}
.highcharts-data-table th,
.highcharts-data-table td {
  background-color: var(--background) !important;
}
.highcharts-data-table table * {
  border: 1px solid var(--border) !important;
}
.highcharts-menu,
.highcharts-menu-item {
  background-color: var(--background) !important;
  color: var(--foreground) !important;
}
.highcharts-menu {
  padding: 4px !important;
  border: 1px solid var(--border) !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.highcharts-menu-item {
  border-radius: 4px !important;
}
.highcharts-menu-item:hover {
  background-color: var(--muted) !important;
}
.highcharts-container {
  @apply  w-full h-full flex justify-center items-center !important;
}
