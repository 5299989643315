@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./theme.css";

/* highest common class (non-tailwind) abstractions go here 👇🏽 */
@import "../common/src/styles/global.css";
/* tailwind typography abstractions go here 👇🏽 */
@import "../common/src/styles/typography.css";
/* generic  tailwind class abstractions go here 👇🏽 */
@import "../common/src/styles/common.css";
/* component specific class abstractions go here 👇🏽 */
@import "../common/src/styles/components.css";

/* external libraries 👇🏽 */
@import "../common/src/styles/lib/nprogress.css";
@import "../common/src/styles/lib/rheostat.css";
@import "../common/src/styles/lib/radio.css";
@import "../common/src/styles/lib/highcharts.css";
@import "../common/src/styles/lib/datePicker.css";
@import "../common/src/styles/lib/colorInput.css";
@import "../common/src/editor/css/editorDefaults.css";
@import "../common/src/editor/css/fullpage.css";

/*@import 'react-date-range/dist/theme/default.css'; 
 * Theme css has been overridden in reactDateRangesOverride
 * */
/* order needs to be maintained */
@import "react-date-range/dist/styles.css"; /* imp  */
@import "../common/src/styles/lib/reactDateRangeOverrides.css";
